window.Client = {
  init: function() {
    // Do something on initialization
  },

  onRender: function(screenId) {
    // Do something when the flow renders
  },


}

window.addEventListener('heyflow-screen-view', (event) => {
  if(event.detail.screenName == "termin"){
    populateDaySelect();
  }
});

window.addEventListener('heyflow-init', (event) => {
  populateDaySelect();
})

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

// Function to get day name (e.g., "Monday")
function getDayName(date) {
  return date.toLocaleDateString('en-US', { weekday: 'long' });
}

// Function to fetch available slots from the API
async function fetchAvailableSlots() {
  try {
    const response = await fetch('https://app.eaze.de/heyflow/time_slots');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching available slots:', error);
    return [];
  }
}

// Function to populate the day select element
async function populateDaySelect() {
  const select = document.querySelector('[data-variable="Tag9"]');
  if (!select) {
    console.error('Select element with data-variable="Tag9" not found');
    return;
  }

  // Clear existing options
  select.innerHTML = '';

  // Add a loading option
  const loadingOption = document.createElement('option');
  loadingOption.disabled = true;
  loadingOption.selected = true;
  loadingOption.value = '';
  loadingOption.textContent = 'Lädt...'; // "Loading..." in German
  select.appendChild(loadingOption);

  // Disable the select element
  select.disabled = true;

  try {
    const availableSlots = await fetchAvailableSlots();
    select.disabled = false;
    select.innerHTML = '';

    // Add default option
    addDefaultOption(select);

    // Populate options
    availableSlots.forEach(slot => {
      const option = document.createElement('option');
      const date = new Date(slot.value);
      // Format day name and date in German
      const dayName = date.toLocaleDateString('de-DE', { weekday: 'long' });
      const formattedDate = date.toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });

      option.value = slot.value;
      option.textContent = `${dayName} (${formattedDate})`;
      select.appendChild(option);
    });

    // Add change event listener
    select.addEventListener('change', function() {
      populateHours(availableSlots);
    });

  } catch (error) {
    console.error('Error fetching available slots:', error);
    select.innerHTML = ''; // Clear existing options
    const errorOption = document.createElement('option');
    errorOption.disabled = true;
    errorOption.selected = true;
    errorOption.value = '';
    errorOption.textContent = 'Fehler beim Laden';
    select.appendChild(errorOption);
  }
}

// Function to populate the hours select element
function populateHours(availableSlots) {
  const daySelect = document.querySelector('[data-variable="Tag9"]');
  const hourSelect = document.querySelector('[data-variable="Zeit"]'); // Assuming this is the correct data-variable for hours

  if (!daySelect || !hourSelect) {
    console.error('Required select elements not found');
    return;
  }

  const selectedDay = daySelect.value;
  const selectedSlot = availableSlots.find(slot => slot.value === selectedDay);

  hourSelect.innerHTML = '';
  addDefaultOption(hourSelect);

  if (selectedSlot) {
    selectedSlot.slots.forEach(time => {
      const option = document.createElement('option');
      option.value = time;
      option.textContent = time;
      hourSelect.appendChild(option);
    });
  }
}

function enableHourSelect(){
    const zeitElement = document.querySelector('[data-variable="Zeit"]');
    const selectElement = zeitElement.closest('[data-blocktype="select"]');
    selectElement.classList.remove("hide_desktop")
    selectElement.classList.remove("hide_mobile")
}


function addDefaultOption(select) {
  const defaultOption = document.createElement('option');
  defaultOption.disabled = true;
  defaultOption.selected = true;
  defaultOption.value = '';
  defaultOption.textContent = 'Klicke zum Auswählen';
  select.appendChild(defaultOption);
}
